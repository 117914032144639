import React from 'react';
import { render } from "react-dom";
import {BrowserRouter, Routes, Route} from "react-router-dom";

// Components
import List         from './Routes/Plants/List';
import Details      from './Routes/Plants/Details';
import DetailsAssociation      from './Routes/Plants/DetailsAssociation';
import Legals       from "./Routes/Pages/Legals";
import Lexique      from "./Routes/Pages/Lexique";
import Landing      from "./Routes/Pages/Landing";


let enter = () => {
    alert('enter');
}


let leave = () => {
    alert('leave');
}


render(
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Landing />}/>
                <Route path="/plants" element={<List />}/>
                <Route path="/plant/:id" element={<Details />} />
                <Route path="/plant/:id/association/:ida" element={<DetailsAssociation />} />
                <Route path="a-propos" element={<Legals />} />
                <Route path="lexique" element={<Lexique />} />
            </Routes>
        </BrowserRouter>
   ,document.getElementById("root")
);
