import React, {useState, useEffect} from 'react';

import { useParams} from "react-router-dom";

import api from '../../Components/Services/Api';

import '../../Assets/App.css';
import Navigation from "../../Routes/Navigation";
import Header from "../../Routes/Plants/HeaderDetails";
import Distance from "../../Assets/Images/distance.svg";
import Period from "../../Assets/Images/period.svg";
import Loader from "../../Routes/Loader";


export default function List(props) {
    const [loading, setLoading] = useState('loading');
    const [plant, setPlant] = useState([]);
    const [friend,setFriend] = useState([]);
    const [asso,setAsso] = useState([]);


    const {id,ida} = useParams();

    useEffect(() => {

        if(id){

            api.call('perma_associations/'+ida, 'GET').then(results => {
                if (results && results.id_plant_1 && results.id_plant_2) {
                    setAsso(results);

                    api.call('perma_plants/'+results.id_plant_1, 'GET').then(results => {
                        if (results) {
                            setPlant(results);
                        }
                    });

                    api.call('perma_plants/'+results.id_plant_2, 'GET').then(results => {
                        if (results) {
                            setFriend(results);
                        }
                    });

                    setTimeout(()=>{
                        setLoading('loaded');
                    },300);
                }else{
                    setTimeout(()=>{
                        setLoading('loaded');
                    },300);
                }
            });

        }

    }, [id,ida]);


    return (
        <div className="App">
            <Loader state={loading} />
            <Header plant={plant} friend={friend}  back={'/plant/'+id} />
            <div className="content">
                <Navigation />
                <div className="plantContent plantAsso">
                    <div className="infoContent">
                            <div className="title">
                                <img src={Distance} alt="Distance" />
                                <span>Distances</span>
                            </div>
                            <div className="infosPlants">
                                <span className="plantTitle">{plant.name}</span>
                                <p>
                                    {plant.distance}
                                </p>
                                <div className="sepLine" ></div>
                                <span className="plantTitle">{friend.name}</span>
                                <p>
                                    {friend.distance}
                                </p>
                            </div>
                        </div>
                    <div className="infoContent">
                        <div className="title">
                            <img src={Period} alt="Period" />
                            <span>Infos & calendrier</span>
                        </div>
                        <div className="infosPlants">
                            <span className="plantTitle">{plant.name}</span>
                            <p>
                                {plant.period}
                            </p>
                            <div className="sepLine" ></div>
                            <span className="plantTitle">{friend.name}</span>
                            <p>
                                {friend.period}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="assoDescription">
                    <p>{asso.description}</p>
                </div>
                {asso.biodiversite ?
                    <div className="assoBio">
                        <img src={asso.illustration_biodiversite} />
                        <p>
                            <strong className="titleBio">Biodiversité</strong>
                            {asso.biodiversite}
                        </p>
                    </div>
                :
                    <div></div>
                }

            </div>
        </div>
    );
}

