import React, {useState, useEffect} from 'react';

import loader from "../Assets/Images/loader.svg";


export default function Loader(props) {
    let state = props.state;
    return (
        <div className={'loader '+state}>
            <img src={loader} />
            <span>Chargement</span>
        </div>
    );
}
