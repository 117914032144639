import React, {useState, useEffect} from 'react';

import {Link, useParams} from "react-router-dom";

import api from '../../Components/Services/Api';

import '../../Assets/App.css';
import Navigation from "../../Routes/Navigation";
import Header from "../../Routes/Plants/HeaderDetails";
import empty from "../../Assets/Images/empty.png";
import Loader from "../../Routes/Loader";

export default function List(props) {
    const [loading, setLoading] = useState('loading');
    const [plant, setPlant] = useState([]);
    const [friends, setFriends] = useState([]);
    const [unfriends, setUnfriends] = useState([]);

    const { id } = useParams();

    useEffect(() => {

        if(id){
            api.call('perma_plants/'+id, 'GET').then(results => {
                if (results) {
                    setPlant(results);
                }
            });

            api.call('perma_associations?plant_id='+id, 'GET').then(results => {
                if (results.perma_associations) {

                    let goodAssoc = [];
                    let wrongAssoc = [];

                    for(let item of results.perma_associations){
                        if(parseInt(item.advised) === 1){
                            goodAssoc.push(item);
                        }else{
                            wrongAssoc.push(item);
                        }
                    }
                    setFriends(goodAssoc);
                    setUnfriends(wrongAssoc);
                    setTimeout(()=>{
                        setLoading('loaded');
                    },300);
                }
            });

        }

    }, [id]);

    const friendItems = friends.map((p) =>
        <Link  className="plantItem goodItem" to={"/plant/"+id+"/association/"+p.id} key={p.id}>
            {p.id_plant_1 === plant.id?
                <div>
                    {p.plant_2_image?
                        <img src={p.plant_2_image} alt={p.plant_2_name} />
                        :
                        <img src={empty} alt={p.plant_2_name} />
                    }
                    <span>{p.plant_2_name}</span>
                </div>
            :
                <div>
                    {p.plant_1_image?
                        <img src={p.plant_1_image} alt={p.plant_1_name} />
                        :
                        <img src={empty} alt={p.plant_1_name} />
                    }
                    <span>{p.plant_1_name}</span>
                </div>
            }
        </Link>
    );

    const wrongItems = unfriends.map((p) =>
        <div  className="plantItem wrongItem" key={p.id}>
            {p.id_plant_1 === plant.id?
                <div>
                    {p.plant_2_image?
                        <img src={p.plant_2_image} alt={p.plant_2_name} />
                        :
                        <img src={empty} alt={p.plant_2_name} />
                    }
                    <span>{p.plant_2_name}</span>
                </div>
                :
                <div>
                    {p.plant_1_image?
                        <img src={p.plant_1_image} alt={p.plant_1_name} />
                        :
                        <img src={empty} alt={p.plant_1_name} />
                    }
                    <span>{p.plant_1_name}</span>
                </div>
            }
        </div>
    );

    return (
        <div className="App">
            <Loader state={loading} />
            <Header plant={plant} back={"/plants"} />
            <div className="content">
                <Navigation />
                <div className="plantContent">
                    <strong className="title">Choisis une copine</strong>
                    <ul className="plantList">{friendItems}</ul>
                    <div className='sep'></div>
                    {wrongItems.length >0?
                        <div>
                            <strong className="title">Cette plante n'aime pas trop</strong>
                            <ul className="plantList">{wrongItems}</ul>
                        </div>
                    :
                        <div className="emptyWrong"></div>
                    }
                </div>
            </div>
        </div>
    );
}

