import Config from '../Config';

const token  = 'cXVlbnRpbkBicmFpbm1hZGUuaW86cXVlbnRpbjAx';
const apiUrl = Config.apiUrl;

const securedRequest = (endpoint, httpMethod, requestDatas) => {
  let url = apiUrl + endpoint;
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: httpMethod,
      headers: {
        Authorization: 'Basic ' + token,
      },
      body: JSON.stringify(requestDatas),
    })
      .then(response => {
        if (
          response.status !== 200 &&
          response.status !== 201 &&
          response.status !== 204
        ) {
          response.json().then(json => {
            if (Config.debugRest) {
              console.warn('[API ERROR]', json);
            }
            reject(json);
          });
        } else {
          response.text().then(
            text => {
              try {
                resolve(JSON.parse(text));
              } catch (error) {
                resolve(text);
              }
            },
            error => {
              console.warn(error);
              reject(error);
            },
          );
        }
      }, reject)
      .catch(reject);
  });
};

const simpleRequest = (
  endpoint,
  httpMethod,
  requestDatas,
  external = false,
) => {
  return new Promise((resolve, reject) => {
    let url = apiUrl + endpoint;
    let options = {
      method: httpMethod,
    };

    if (external) {
      url = endpoint;
    }

    if (Object.keys(requestDatas).length > 0) {
      options.body = JSON.stringify(requestDatas);
    }

    fetch(url, options)
      .then(function (response) {
        console.log(response);

        if (
          response.status !== 200 &&
          response.status !== 201 &&
          response.status !== 204
        ) {
          response.text().then(
            text => {
              console.log(text);
              try {
                resolve(JSON.parse(text));
              } catch (error) {
                resolve(text);
              }
            },
            error => {
              console.warn(error);
              reject(error);
            },
          );
        } else {
          response.text().then(
            text => {
              try {
                resolve(JSON.parse(text));
              } catch (error) {
                console.log(text);
                resolve(text);
              }
            },
            error => {
              reject(error);
            },
          );
        }
      }, reject)
      .catch(reject);
  });
};

let ApiObject = {
    call(endPoint, method, datas, secured = true) {
        if (secured) {
            return securedRequest(endPoint, method, datas);
        } else {
            return simpleRequest(endPoint, method, datas);
        }
    }
}
export default ApiObject;
