import '../../Assets/App.css';
import Navigation from "../../Routes/Navigation";
import Header from "../Header";
import React, {useState} from "react";
import Loader from "../../Routes/Loader";

export default function Lexique() {

    const [loading, setLoading] = useState('loading');

    setTimeout(()=>{
        setLoading('loaded');
    },100);


    return (
        <div className="App">
            <Loader state={loading} />
            <Header/>
            <div className="content">
                <Navigation/>
                <div className="plantContent">
                    <h1>Lexique</h1>
                    <ul className="lexique">
                        <li>
                            <strong>Allélopathie</strong>
                            <p>
                                phénomène biologique par lequel un organisme produit une ou plusieurs substances biochimiques qui influencent la germination, la croissance, la survie et la reproduction d'autres organismes.
                            </p>
                        </li>
                        <li>
                            <strong>Alliaceae ou Alliacées</strong>
                            <p>
                                Famille de plantes parmi lesquels se retrouvent les oignons, les poireaux, la ciboulette…
                            </p>
                        </li>
                        <li>
                            <strong>Altise</strong>
                            <p>
                                insecte qui parasite de nombreuses plantes potagères dès le printemps.
                            </p>
                        </li>
                        <li>
                            <strong>Apiaceae ou Apiacées</strong>
                            <p>
                                Famille de plantes anciennement nommée Ombellifères, elle regroupe entre autres le panais, la carotte, le céleri, le fenouil…
                            </p>
                        </li>
                        <li>
                            <strong>Auxiliaire de culture</strong>
                            <p>
                                Organisme vivant qui fournit des services écosystémiques permettant de faciliter la production agricole.
                            </p>
                        </li>
                        <li>
                            <strong>Azote</strong>
                            <p>
                                Élément nutritif essentiel à la croissance des cultures et dont le symbole est N.
                            </p>
                        </li>
                        <li>
                            <strong>Bisannuel</strong>
                            <p>
                                se dit d'une plante qui a un cycle vital de deux ans
                            </p>
                        </li>
                        <li>
                            <strong>Bulbe</strong>
                            <p>
                                renflement que la plante forme sous terre et qui est raciné à la base. Cet organe sert de stockage de nourriture pour la plante.
                            </p>
                        </li>
                        <li>
                            <strong>Caïeu</strong>
                            <p>
                                jeune bulbe produit par un bulbe principal, la « gousse d'ail » étant un bon exemple
                            </p>
                        </li>
                        <li>
                            <strong>Chrysope</strong>
                            <p>
                                insecte dont les larves dévorent les pucerons, les acariens, mouches blanches
                            </p>
                        </li>
                        <li>
                            <strong>Fongicide</strong>
                            <p>
                                qui permet d'éliminer ou de limiter le développement de champignons
                            </p>
                        </li>
                        <li>
                            <strong>Insecticide</strong>
                            <p>
                                qui permet d'éliminer ou de limiter le développement d'insectes
                            </p>
                        </li>
                        <li>
                            <strong>Légume feuille</strong>
                            <p>
                                légume dont la partie consommée correspond aux feuilles de la plante
                            </p>
                        </li>
                        <li>
                            <strong>Légume racine</strong>
                            <p>
                                légume avec des racines hyper développées constituant des organes de réserve
                            </p>
                        </li>
                        <li>
                            <strong>Légumineuse</strong>
                            <p>
                                plante dont les fruits comestibles sont contenus dans des gousses et qui a la particularité de fournir de l’azote au sol.
                            </p>
                        </li>
                        <li>
                            <strong>Mellifère</strong>
                            <p>
                                produisant de bonnes quantités de nectar et de pollen de bonne qualité et accessibles par les abeilles
                            </p>
                        </li>
                        <li>
                            <strong>Nématocide ou nématicide</strong>
                            <p>
                                qui permet d'éliminer ou de limiter le développement des nématodes
                            </p>
                        </li>
                        <li>
                            <strong>Nématode</strong>
                            <p>
                                nématodes (Nematoda), ou vers ronds, constituent un embranchement de vers non segmentés, vers microscopiques.
                            </p>
                        </li>
                        <li>
                            <strong>Nuisible</strong>
                            <p>
                                Animal parasite, venimeux ou destructeur
                            </p>
                        </li>
                        <li>
                            <strong>Plante hôte</strong>
                            <p>
                                plante accueillant certaines espèces d'insecte et leur permettant de réaliser leur cycle de vie (ponte d’œufs, développement de larves)
                            </p>
                        </li>
                        <li>
                            <strong>Pollinisation</strong>
                            <p>
                                chez les plantes à fleur, phénomène par lequel, il y a transport du pollen des organes de reproduction mâle (étamines) vers le (ou les) organes de reproduction femelle (pistil)
                            </p>
                        </li>
                        <li>
                            <strong>Punaise</strong>
                            <p>
                                insecte ailé surtout connu pour l'odeur nauséabonde qu'il dégage pour se protéger
                            </p>
                        </li>
                        <li>
                            <strong>Ravageur</strong>
                            <p>
                                organisme qui détruit ou ravage
                            </p>
                        </li>
                        <li>
                            <strong>Repiquage</strong>
                            <p>
                                Action de transplanter une plante venue d’un semis.
                            </p>
                        </li>
                        <li>
                            <strong>Semis</strong>
                            <p>
                                action qui consiste à mettre en terre des graines ou des semences
                            </p>
                        </li>
                        <li>
                            <strong>Semis direct</strong>
                            <p>
                                action de mettre les graines ou semences directement dans le sol
                            </p>
                        </li>
                        <li>
                            <strong>Teigne du poireau</strong>
                            <p>
                                espèce de lépidoptère (papillon) dont la chenille parasite le poireau en creusant des galeries
                            </p>
                        </li>
                        <li>
                            <strong>Tuteur</strong>
                            <p>
                                support qui soutien une plante
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

