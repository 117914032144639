import {Link,useLocation} from "react-router-dom";
import React from "react";

export default function Navigation() {
    const location = useLocation();


    return (
        <div className="navigation">
            <nav className="Nav">
                <Link to="/Lexique"  className={location.pathname ==='/Lexique'?'active':''}>Lexique</Link>
                <Link to="/plants" className={location.pathname ==='/'?'active':''}>Légumes</Link>
                <Link to="/a-propos" className={location.pathname ==='/a-propos'?'active':''}>À propos</Link>
            </nav>
        </div>
    );
}
