import React from "react";
import {Link} from "react-router-dom";
import backImage from "../../Assets/Images/back.svg";

export default function Navigation(props) {

    const plant     = props.plant;
    const friend    = props.friend;
    const back      = props.back;

    return (
        <header className="headerBackground headerDetails">

            {back?
                <Link to={back} >
                    <img className="back" src={backImage} alt={back} />
                </Link>
            :
                <div></div>
            }

            {friend === undefined?
                <div>
                    <h1>{plant.name}</h1>
                    <img src={plant.image} alt={plant.name} className="plantName" />
                </div>
            :
                <div>
                    <div className="names">
                        <h1>{plant.name}</h1>
                        <span>&</span>
                        <h2>{friend.name}</h2>
                    </div>
                    <div className="images">
                        <img src={plant.image} alt={plant.name} />
                        <img src={friend.image} alt={plant.friend} />
                    </div>
                </div>
            }
        </header>
    );
}
