import '../../Assets/App.css';
import Navigation from "../../Routes/Navigation";
import Header from "../Header";
import React, {useState} from "react";
import Loader from "../../Routes/Loader";


// partners


import intradel from "../../Assets/Images/logos/intradel.jpg";
import jr from "../../Assets/Images/logos/jr.jpg";
import liege from "../../Assets/Images/logos/liege.jpg";
import metz from "../../Assets/Images/logos/metz.jpg";
import natagora from "../../Assets/Images/logos/natagora.jpg";
import noe from "../../Assets/Images/logos/noe.jpg";
import europe from "../../Assets/Images/logos/europe.jpg";


export default function Legals() {
    const [loading, setLoading] = useState('loading');

    setTimeout(()=>{
        setLoading('loaded');
    },100);

    return (
        <div className="App">
            <Loader state={loading} />
            <Header/>
            <div className="content">
                <Navigation/>
                <div className="plantContent pageContent">
                    <div className="infos">
                        <strong>À propos</strong>
                        <p>
                            L’application Permacopines a été conçue pour tout jardinier débutant ou amateur qui a décidé de consacrer du temps à un potager, afin que la mise en œuvre soit simple et agréable.
                        </p>
                        <p>
                            Permacopines privilégie des légumes faciles à cultiver et vous aide à les associer selon les principes inspirés de la permaculture. Vous allez pouvoir combiner des légumes par paire ce qui aura pour résultat d’augmenter la production de votre potager tout en contribuant à augmenter la biodiversité de celui-ci. Au final, votre jardin sera encore plus beau et plus résistant aux maladies ainsi qu’aux ravageurs.
                        </p>
                        <p>
                            En bonus, les naturalistes de chez Natagora vous expliquerons les interactions bénéfiques entres les insectes et les végétaux au profit de la biodiversité :
                        </p>
                        <p>
                            <strong className="subtitle">PDF’s et outils à télécharger gratuitement :</strong>
                        </p>
                        <ul>
                            <li><a href="https://www.intradel.be/produire-moins-de-dechets/jardin-ressources-be/mediatheque-du-jardin-ressources.htm?lng=fr" target="_blank">Médiatèque du jardin</a><span> ressources d’Intradel</span></li>
                            <li><a href="https://reseaunature.natagora.be/fichesjardins" target="_blank">Réseau nature natagora </a><span>des fiches pour un jardin naturel</span></li>
                        </ul>
                    </div>
                    <div className="infos">
                        <strong>Comment ça marche ?</strong>
                        <p>
                            Faites un choix parmi les 30 légumes les plus populaires dans nos régions, sélectionnez ensuite les légumes ou aromates suggérés en association par Permacopines.
                        </p>
                        <p>
                            Parfois, vous aurez une indication concernant ce que certains légumes « n’aiment pas trop ». A vous de voir si vous avez envie de tenter l’association. Par exemple, il faut tenir en compte du fait que certains légumes à croissance rapide risquent de faire de l’ombre à des variétés plus petites, ce qui pourrait freiner leur développement et leur goût.
                        </p>
                    </div>
                    <div className="infos">
                        <strong>Vocabulaire ?</strong>
                        <p>
                            Les mots « caïeux », « semis », « altises » ou « nématodes » vous intriguent ? Pas de panique, nous avons créé une rubrique « Lexique » pour que vous puissiez décoder la signification des quelques mots techniques que vous rencontrerez en parcourant Permacopines.
                        </p>
                    </div>
                    <div className="infos">
                        <strong>Limite d’utilisation et mise en garde.</strong>
                        <p>
                            L’équipe de développement du logiciel souhaite mentionner que les associations de légumes et les résultats de production d’un jardin potager vont dépendre de très nombreux facteurs tels que le climat saisonnier, la qualité des semences (de préférence de qualité biologique et paysanne), la régularité du désherbage éventuel, les fréquences de l’arrosage, les techniques de paillage et de couvre sol, la préparation et l’amendement du sol, la typologie du sol, l’orientation du jardin, son climat et son altitude spécifique, l’utilisation d’une serre ou non, la ventilation ou la protection des légumes à l’extérieur ou à l’intérieur, les rotations de culture, etc.
                        </p>
                        <p>
                            Permacopines n’est pas un manuel de jardinage et nous vous suggérons, si vous en ressentez le besoin, de vous renseigner pour suivre des formations proches de chez vous (en ligne et/ou en présentiel, voir notamment www.intradel.be - formations gratuites du Jardin Ressources, Réseau nature – webinaires et ateliers pour plus de biodiversité dans votre jardin) ou d’acquérir des ouvrages de références qui existent sur le marché.
                        </p>
                        <p>
                            Le monde du vivant est complexe et les associations de légumes et d’aromates ne sont pas une science exacte. Par conséquent, Permacopines ne pourra en aucun cas être responsable du résultat de votre production au jardin pour les raisons sus-mentionnées.
                        </p>
                    </div>
                    <div className="infos">
                        <strong>Fiches pédagogiques gratuites</strong>
                        <p>
                            Vous êtes pressé-e et vous débutez en jardinage ?<br /> Voici un <a href="https://www.intradel.be/produire-moins-de-dechets/jardin-ressources-be/mediatheque-du-jardin-ressources.htm?lng=fr" target="_blank">lien vers 10 fiches gratuites</a> pour vous aider à planifier dès maintenant votre saison de jardinage de A à Z.
                        </p>
                    </div>
                    <div className="infos">
                        <strong>Équipe et partenaires</strong>
                        <p>
                            Les contenus de Permacopines furent conçus par une équipe de professionnels qui se sont inspirés de nombreux ouvrages et de leur propre retour d’expérience :
                        </p>
                        <ul className="teamList">
                            <li>Le technicien du Service Agriculture de le Province de Liège qui accompagne les 80 maraîchers bios sur ce territoire ; </li>
                            <li>Le Petit Monde de Desnié (Theux) ;</li>
                            <li>L’équipe du Jardin Ressources d’Intradel ; </li>
                            <li>L’asbl Natagora ; </li>
                            <li>Et des maraîchers professionnels de la Province de Liège et de la Ville de Metz.</li>
                        </ul>
                        <p>
                            Permacopines fut soutenu par les fonds européens INTERREG VA Grande Région – projet NOÉ-NOAH –qui a donné naissance à un collectif de 27 partenaires transfrontaliers.
                        </p>
                        <p>
                            De 2017 à 2021, ils ont développé un réseau partagé de reconnexions écologiques innovantes et citoyennes en Grande Région, lié par un double objectif : « valoriser et préserver les corridors de biodiversité, tout en tissant du lien social entre les habitants, avec une attention portée aux enfants et aux personnes les plus vulnérables ». Ensemble, les partenaires ont développé plus de 200 actions : aménagements de jardins, actions de formations tout public ou formations professionnelles, événements culturels, stands d’informations, projets innovants…
                        </p>
                        <p>
                            © 2022 Natagora, tous droits réservés – <a href="www.natagora.be" target="_blank">www.natagora.be</a>
                        </p>
                        <div className="partnersInfos">
                            <img className="partner" src={noe} alt="Noe-Noah" />
                            <img className="partner" src={natagora} alt="Natagora" />
                            <img className="partner" src={jr} alt="Jardin Ressources" />
                            <img className="partner" src={intradel} alt="intradel" />
                            <img className="partner" src={liege} alt="Liège" />
                            <img className="partner" src={metz} alt="Metz" />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}


