import React, {useState, useEffect} from 'react';

import api from '../../Components/Services/Api';

import '../../Assets/App.css';
import Navigation from "../../Routes/Navigation";
import Loader from "../../Routes/Loader";
import Header from "../../Routes/Header";

import empty from "../../Assets/Images/empty.png";
import {Link} from "react-router-dom";



export default function List() {

    const [plants, setPlants] = useState([]);
    const [loading, setLoading] = useState('loading');

    useEffect(() => {
        api.call('perma_plants?page=1&orderBy=name&order=DESC&nbItems=999999', 'GET').then(results => {
            if (results.perma_plants) {
                let plants = [];
                for(let plant of results.perma_plants){
                   if(parseInt(plant.is_main_active)){
                       plants.push(plant);
                   }
                }
                setPlants(plants);
                setTimeout(()=>{
                    setLoading('loaded');
                },300);
            }
        });
    }, []);

    const listItems = plants.map((p) =>
        <Link  className="plantItem" to={"/plant/"+p.id} key={p.id}>
            {p.image?
                <img src={p.image} alt={p.name} />
                :
                <img src={empty} alt={p.name} />
            }
            <span>{p.name}</span>
        </Link>
    );

    return (
        <div className="App">
            <Loader state={loading} />
            <Header />
            <div className="content" >
                <Navigation />
                <div className="plantContent">
                    <h1>Choisis un légume à associer !</h1>
                    <ul className="plantList">{listItems}</ul>
                </div>
            </div>
        </div>
    );
}

